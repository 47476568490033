import { PageName } from '$lib/enums/feedback';
import { writable } from 'svelte/store';

const feedbackStore = writable<{
  pageName: string;
}>({ pageName: PageName.ALL_PROJECTS });

export default {
  subscribe: feedbackStore.subscribe,
  set: feedbackStore.set,
};
